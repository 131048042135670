<template>
  <div class="popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】提卸货地址信息' : (todo == 'edit' ? '【修改】提卸货地址信息' : '【查看】提卸货地址信息')"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" size="small">
        <!--<el-form-item label="地址编号" prop="addressCode">-->
          <!--<el-input v-model="dataForm.addressCode" readonly="readonly" placeholder="自动生成"></el-input>-->
        <!--</el-form-item>-->
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="地址类型" prop="addressType">
              <el-select class="item-choose"  :disabled="true" v-model="dataForm.addressType" style="width: 100%;" filterable clearable
                        placeholder="请选择">
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属客户" prop="customerId">
              <el-select class="item-choose"
                        :disabled="true"
                         v-model="dataForm.customerId"
                         style="width: 100%;" filterable clearable
                        placeholder="请选择">
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.simpleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="工厂名称" prop="simpleName">
              <el-input v-model="dataForm.simpleName"  :disabled="todo == 'view' ? true : false" placeholder="工厂名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="0">
          <el-form-item label="工厂信息" :rules="[{ required: true }]">
            <el-col :span="8">
              <el-input v-model="dataForm.simpleName"  :disabled="todo == 'view' ? true : false" placeholder="工厂名称"></el-input>
            </el-col>
            <el-col :span="16">
              <el-cascader
                :disabled="todo == 'view' ? true : false"
                style="width: 100%"
                v-model="dataForm.cityList"
                :options="$store.state.areaList"
                clearable
                filterable
                change-on-select
                :props="{ checkStrictly: false, value : 'name', label : 'name' }"
                placeholder="选择省-市-区"></el-cascader>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="详细地址" prop="address">
              <el-input v-model="dataForm.address" placeholder="地址" :disabled="todo == 'view' ? true : false"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model="dataForm.contactName" placeholder="联系人"  :disabled="todo == 'view' ? true : false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input v-model="dataForm.contactPhone" placeholder="可输入手机号码或固定电话"  :disabled="todo == 'view' ? true : false"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50" v-if="todo != 'add'">
          <el-col :span="12">
            <el-form-item label="经度" prop="longitude">
              <el-input v-model="dataForm.longitude" placeholder="经度"  :disabled="todo == 'view' ? true : false"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度" prop="latitude">
              <el-input v-model="dataForm.latitude" placeholder="纬度"  :disabled="todo == 'view' ? true : false"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="注意事项" prop="remark">
              <el-input type="textarea" :rows="2" v-model="dataForm.remark" placeholder="注意事项"  :disabled="todo == 'view' ? true : false"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isPhone, isMobile } from '@/utils/validate'
export default {
  data () {
    let validateMobile = (rule, value, callback) => {
      if (!isMobile(value) && !isPhone(value)) {
        callback(new Error('格式错误，可输入手机号码或固定电话'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      isCustomer: false,
      dataForm: {
        id: null,
        customerId: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        address: null,
        addressType: null,
        remark: null,
        latitude: null,
        longitude: null,
        cityList: null
      },
      areaList: [],
      todo: 'view',
      typeList: [
        {
          label: '提货',
          value: 1
        },
        {
          label: '卸货',
          value: 2
        }
      ],
      customerList: [],
      dataRule: {
        customerId: [
          { required: true, message: '请选择所属客户', trigger: 'change' }
        ],
        simpleName: [
          { required: true, message: '工厂名称不能为空', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '详细地址不能为空', trigger: 'blur' }
        ],
        addressType: [
          { required: true, message: '请选择地址类型', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    clearForm () {
      this.dataForm = {
        id: null,
        customerId: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        address: null,
        addressType: null,
        remark: null,
        latitude: null,
        longitude: null,
        cityList: null
      }
    },
    init (todo, customerId, addressType, id) {
      this.clearForm()
      this.dataForm.id = id
      this.todo = todo
      this.dataForm.customerId = customerId
      this.dataForm.addressType = addressType
      if (this.currentUser.customerId) {
        this.isCustomer = true
      }
      // this.getAreaList()
      this.$http({
        url: this.$http.adornUrl('/customer/findAll'),
        method: 'get',
        params: this.$http.adornParams(this.dataForm.id)
      }).then(({ data }) => {
        this.customerList = data
      }).then(() => {
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/customerAddress/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({data}) => {
            if (data) {
              this.dataForm.latitude = data.latitude
              this.dataForm.longitude = data.longitude
              this.dataForm.simpleName = data.simpleName
              this.dataForm.contactName = data.contactName
              this.dataForm.contactPhone = data.contactPhone
              this.dataForm.address = data.address
              this.dataForm.addressType = data.addressType
              this.dataForm.customerId = data.customerId
              this.dataForm.remark = data.remark
              this.dataForm.cityList = data.cityList
            }
          })
        }
      })
    },
    // 获取省市区信息
    getAreaList () {
      this.$http({
        url: this.$http.adornUrl(`/areaManager/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.areaList = data
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.dataForm.cityList.length < 1) {
            this.$message.error('请选择省市区')
            return
          }
          if (!this.dataForm.address) {
            this.$message.error('请输入地址')
            return
          }
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/customerAddress/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'customerId': this.dataForm.customerId,
              'simpleName': this.dataForm.simpleName,
              'address': this.dataForm.address,
              'contactName': this.dataForm.contactName,
              'contactPhone': this.dataForm.contactPhone,
              'addressType': this.dataForm.addressType,
              'remark': this.dataForm.remark,
              'longitude': this.dataForm.longitude,
              'latitude': this.dataForm.latitude,
              'cityList': this.dataForm.cityList,
              'type': 1
            })
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
